<template>
  <b-card>
    <b-card-title class="mb-1">
      {{ $t(i18nTitleVariable) }}
    </b-card-title>
    <!-- <b-card-sub-title class="mb-2">
      Spending on various categories
    </b-card-sub-title> -->

    <vue-apex-charts
      type="donut"
      height="350"
      :options="this.options"
      :series="this.series"
    />
  </b-card>
</template>

<script>
import { BCard, BCardTitle, BCardSubTitle } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import i18n from '@/libs/i18n'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    i18nTitleVariable: {
      type: String,
      required: true,
    },
    i18nStagesVariable: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      stagesColors: {
        registration: '#0E2038',
        analysis: '#213A5C',
        approval: '#4A6B8A',
        corrective: '#758D93',
        verification: '#90A4B0',
        correctiveMeasures: '#A3B5C0',
        implementation: '#B8C9D6',
        finalized: '#C4B8A8',
        end: '#DAD5CF',
      }
    }
  },
  computed: {
    series() {
      return this.data.values
    },
    options() {
      const colorsMapped = this.data.labels.map(e => this.stagesColors[e])
      const total = this.data.total
      return {
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        // colors: [
        //   '#ffe700',
        //   '#00d4bd',
        //   '#826bf8',
        //   '#2b9bf4',
        //   '#FFA1A1',
        // ],
        colors: colorsMapped,
        dataLabels: {
          enabled: true,
          formatter(val) {
            // eslint-disable-next-line radix
            return `${parseInt(val)}%`
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1.5rem',
                  fontFamily: 'Montserrat',
                  formatter(val) {
                    // eslint-disable-next-line radix
                    // return `${parseInt(val)}%`
                    return val
                  },
                },
                total: {
                  show: true,
                  fontSize: '1.5rem',
                  label: 'Total',
                  formatter() {
                    return total
                  },
                },
              },
            },
          },
        },
        // labels: ['Operational', 'Networking', 'Hiring', 'R&D'],
        labels: this.data.labels.map(e => i18n.t(e ? `${this.i18nStagesVariable}.${e}` : 'undefined')),
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 380,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: 320,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        fontSize: '1.5rem',
                      },
                      value: {
                        fontSize: '1rem',
                      },
                      total: {
                        fontSize: '1.5rem',
                      },
                    },
                  },
                },
              },
              legend: {
                show: true,
              },
            },
          },
        ],
        chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              const index = config.selectedDataPoints[0][0]
              const stage = this.data.labels[index]
              this.$emit('filterByStage', stage)
            },
            dataPointMouseEnter: function(event) {
              event.target.style.cursor = "pointer"
            }
          },
        },
      }
    },
  },
}
</script>